import React, { useState } from "react";
import styles from "./Contacts.module.scss";
import ContactsImage from "../../assets/images/ContactsImage.png";
import Button from "../../components/shared/Button/Button";
import EmailHover from "../../assets/icons/email-icon-hover.gif";
import Email from "../../assets/icons/email-icon.svg";
import PhoneHover from "../../assets/icons/phone-icon-hover.gif";
import Phone from "../../assets/icons/phone-icon.svg";
import Location from "../../assets/icons/location-icon.svg";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { ImageRightAnimation } from "../../styles/animations";
import { motion } from "framer-motion";
import { useDevice } from "../../hooks/useDevice";

const Contacts = () => {
  const { t } = useTranslation();
  const countries = [
    {
      id: "israel",
      label: t("Israel"),
    },
    {
      id: "ukraine",
      label: t("Ukraine"),
    },
    // {
    //   id: "australia",
    //   label: t("Australia"),
    // },
  ];
  const [emailIcon, setEmailIcon] = useState(Email);
  const [phoneIcon, setPhoneIcon] = useState(Phone);
  const [country, setCountry] = useState(countries[0]);
  const deviceType = useDevice();
  const mobileVersion = deviceType === "MOBILE";

  const contacts = [
    {
      location: <Trans i18nKey={"locationIsrael"} />,
      telephone: "+972 53 457 54 34",
      email: "sales@intranso.com",
    },
    {
      location: <Trans i18nKey={"locationUkraine"} />,
      telephone: "+380 68 269 97 70",
      email: "sales@intranso.com",
    },
  ];

  const contactsByLocation = () => {
    switch (country.id) {
      case "israel":
        return contacts[0];
      case "ukraine":
        return contacts[1];
      case "australia":
        return contacts[0];
      default:
        return null;
    }
  };
  const onMouseUpEmailHandle = (e) => {
    setEmailIcon(EmailHover);
  };
  const onMouseLeaveEmailHandle = (e) => {
    setEmailIcon(Email);
  };
  const onMouseUpPhoneHandle = (e) => {
    setPhoneIcon(PhoneHover);
  };
  const onMouseLeavePhoneHandle = (e) => {
    setPhoneIcon(Phone);
  };
  return (
    <div className={styles.contactsWrapper}>
      <div className={styles.contactsInfo}>
        <h1 className={styles.contactsTitle}>
          {deviceType === "MOBILE" ? (
            <Trans i18nKey={"Contact us mobile"} />
          ) : (
            t("Contact us")
          )}
        </h1>
        <p className={styles.description}>
          <Trans
            i18nKey={
              mobileVersion
                ? "Our managers will help you choose the most effective solution for the development of your business mobile"
                : "Our managers will help you choose the most effective solution for the development of your business"
            }
          ></Trans>
        </p>
        {deviceType !== "MOBILE" ? (
          <>
            <div className={styles.buttonWrapper}>
              {countries.map((countryItem) => (
                <Button
                  key={countryItem.id}
                  title={countryItem.label}
                  disabled={countryItem.id === "australia"}
                  className={cn(styles.buttonCountry, {
                    [styles.activeButton]: country.id === countryItem.id,
                  })}
                  onClick={() => setCountry(countryItem)}
                />
              ))}
            </div>
            <div className={styles.line} />
            <ul className={styles.contactsList}>
              <div className={cn(styles.contactItem, styles.location)}>
                <img
                  src={Location}
                  alt={"email"}
                  style={{ marginTop: "3px" }}
                />
                <span>{contactsByLocation().location}</span>
              </div>
              <div className={styles.telephoneEmail}>
                <div
                  onMouseEnter={onMouseUpPhoneHandle}
                  onMouseLeave={onMouseLeavePhoneHandle}
                  className={cn(styles.contactItem, styles.telephone)}
                >
                  <img src={phoneIcon} alt={"email"} />
                  <a href={`tel:${contactsByLocation().telephone}`}>
                    {contactsByLocation().telephone}
                  </a>
                </div>
                <div
                  onMouseEnter={onMouseUpEmailHandle}
                  onMouseLeave={onMouseLeaveEmailHandle}
                  className={cn(styles.contactItem, styles.email)}
                >
                  <img src={emailIcon} alt={"email"} />
                  <a href={`mailto:${contactsByLocation().email}`}>
                    {contactsByLocation().email}
                  </a>
                </div>
              </div>
            </ul>
          </>
        ) : (
          contacts.map((contact, index) => (
            <div className={styles.contactsListMobile} key={index}>
              <Button
                title={countries[index].label}
                disabled={false}
                className={cn(styles.buttonCountry, {})}
                //onClick={() => setCountry(countryItem)}
              />
              <div className={styles.line} />
              <ul className={styles.contactsList}>
                <div className={cn(styles.contactItem)}>
                  <img
                    src={Location}
                    alt={"email"}
                    style={{ marginTop: "3px" }}
                  />
                  <span>{contact.location}</span>
                </div>
                <div
                  onMouseEnter={onMouseUpPhoneHandle}
                  onMouseLeave={onMouseLeavePhoneHandle}
                  className={cn(styles.contactItem)}
                >
                  <img src={phoneIcon} alt={"email"} />
                  <a href={`tel:${contact.telephone}`}>{contact.telephone}</a>
                </div>
                <div
                  onMouseEnter={onMouseUpEmailHandle}
                  onMouseLeave={onMouseLeaveEmailHandle}
                  className={cn(styles.contactItem, styles.telephoneEmail)}
                >
                  <img src={emailIcon} alt={"email"} />
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </div>
              </ul>
            </div>
          ))
        )}
      </div>
      <motion.img
        initial={"hidden"}
        whileInView={"visible"}
        viewport={{ once: true }}
        variants={ImageRightAnimation}
        className={styles.contactsImage}
        src={ContactsImage}
        alt={"contacts"}
      />
    </div>
  );
};

export default Contacts;
